<template>
  <div class="flex flex-col w-full banner bg-cover bg-center bg-fixed">
    <div class="flex justify-center">
      <div class="w-full flex flex-wrap">
        <div
          class="py-12 lg:px-40 px-20 lg:w-2/3 w-full bg-black h-112
          flex flex-col justify-center text-white"
        >
          <span class="text-3xl md:text-4xl font-bold">
            MRC HR Tools and Data Services
          </span>
          <span class="text-2xl mb-6">powered by A.I. & machine learning</span>
          <span class="text-2xl md:text-3xl font-bold">Provide a holistic view on</span>
          <span class="text-2xl md:text-3xl font-bold">latest HR & C&B market trends. </span>
        </div>
        <img
          src="../assets/img/tools_pic1.jpg"
          alt="picture"
          class="lg:w-1/3 w-full md:text-xl text-lg"
        />
      </div>
    </div>
    <div class="flex flex-col items-center">
      <div class="w-full">
        <div class="bg1 bg-cover bg-fixed bg-center py-4">
          <div
            class="flex flex-col bg-white bg-opacity-95 w-full max-w-5xl my-8
          bg-center mx-auto py-4"
          >
            <div class="flex flex-wrap bg-tools-bg1-50 bg-opacity-50 px-4 bg-center">
              <h2 class="text-2xl font-semibold mx-4 p-4 border-b-2 border-red-600 w-full">
                C&B Data Services we offer
              </h2>
              <router-link
                class="my-2 w-full lg:w-1/2"
                v-for="(cnbservice, i) in cnbservices"
                :key="i"
                :to="cnbservice.url"
              >
                <div class="hover:elevation-5 mx-4 hover:bg-white">
                  <h3 class="font-medium px-4 pt-3">{{ cnbservice.title }}</h3>
                  <p class="px-4 pb-3">{{ cnbservice.text }}</p>
                </div>
              </router-link>
            </div>
          </div>

          <div
            class="flex flex-col bg-white bg-opacity-95 w-full max-w-5xl my-8
          bg-center mx-auto"
          >
            <div class="flex flex-wrap bg-tools-bg1-50 bg-opacity-50 px-4 bg-center py-4">
              <h2
                class="text-2xl font-semibold mx-4 p-4 border-b-2 border-red-600 w-full"
              >
                AI Market Sensor (AIMS) to support your HR decisions
              </h2>
              <router-link
                class="my-2 w-full lg:w-1/2"
                v-for="(aims, i) in aims"
                :key="i"
                :to="aims.url"
              >
                <div class="hover:elevation-5 mx-4 hover:bg-white">
                  <h3 class="font-medium px-4 pt-3">{{ aims.title }}</h3>
                  <p class="px-4 pb-3">{{ aims.text }}</p>
                </div>
              </router-link>
            </div>
          </div>

        </div>
        <!-- <div class="bg2 bg-cover bg-fixed bg-center py-5 my-6">

        </div> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg1 {
  background-image: url('../assets/img/tools_bg1.jpg');
  background-size: 100% 100%;
}
.bg2 {
  background-image: url('../assets/img/tools_bg2.jpg');
  background-size: 100% 100%;
}
</style>

<script>
export default {
  name: 'Tools',
  data() {
    return {
      cnbservices: Object.freeze([
        {
          url: '/tools/super_compensation',
          title: 'Super Compensation',
          text:
            'A manifestation of the superintending of pay trends powered by MRC machine learning algorithm & big data',
        },
        {
          url: '/tools/executive_compensation_private',
          title: 'Executive Compensation Survey (Private)',
          text:
            'An APAC job pricing tool for top executives including salary, bonus, benefits, stock and perks',
        },
        {
          url: '/tools/executive_compensation_public',
          title: 'Board of Directors’ Remuneration (Public Listed)',
          text: 'Directors’ fees and remuneration package for board of directors (ED, NED & INED)',
        },
        {
          url: '/tools/sale_incentive_report',
          title: 'Sales Incentive Market Practices Report',
          text:
            'Latest sales commission/bonus/incentive trends, covering all industry / specific industry',
        },
        {
          url: '/tools/expat_practice_report',
          title: 'Expatriate Practice Report',
          text:
            'Latest expatriate practice trends, including all sorts of mobility items, localisation, pension etc.',
        },
        {
          url: '/tools/group_surveys',
          title: 'Industry Club / Group Customised Surveys',
          text: 'Customised / sponsored surveys to target the participants’ specific needs',
        },
        {
          url: '/tools/benefits_data_services',
          title: 'Benefits Data Services - on demand',
          text:
            'Car, transportation, housing, pension, insurance, medical, severance, leaves, membership, education, flexible benefits etc.',
        },
      ]),
      aims: Object.freeze([
        {
          url: '/tools/employment_legislation',
          title: 'Employment Legislations',
          text: 'Access over 180 countries of employment legislations by Country',
        },
        {
          url: '/tools/relocation_calculator',
          title: 'Relocation Calculator',
          text:
            'Stimulate mobility package for domestic / cross-border relocation – unlimited as desired for 10,000 cities',
        },
        {
          url: '/tools/pay_trends',
          title: 'Pay Trends',
          text:
            'Fresh graduates start rates, worldwide minimum wage and living wage by country/region/city',
        },
        {
          url: '/tools/forecast_predictor',
          title: 'Forecast Predictor',
          text:
            'Salary increase forecast predictor for the next 5 years, by country and by industry',
        },
        {
          url: '/tools/productivity_financials',
          title: 'Productivity & Financials',
          text:
            'Access over 38,000 listed companies worldwide to benchmark against your target competitors’ productivity & financials',
        },
        {
          url: '/tools/hr_market',
          title: 'HR Market',
          text: 'Job posting trends by job functions and hierarchies, HR Supply / Demand momentum',
        },
        {
          url: '/tools/economy',
          title: 'Economy',
          text: 'GDP size, growth, inflation, unemployment, investment, etc. by country worldwide',
        },
        {
          url: '/tools/hr_cnb_company_news',
          title: 'HR/C&B/Company News',
          text:
            'COVID-19, Hiring, Pay, Redundancy, Union, M&A, etc. by country, backed by data source, worldwide',
        },
      ]),
    };
  },
};
</script>
